#prerelease-flag{
    background-color: rgb(240, 232, 117);
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    border-radius: 50px;
    border: solid 1px rgb(231, 222, 96);
    color: #605e5c !important;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 5%);
}

#prerelease-flag:hover{
    cursor: pointer;
}