.userDropdown button, .userDropdown.show button, .userDropdown.show button.dropdown-toggle, .userDropdown button:focus, .userDropdown button:active{
    border-radius: 0px !important;
    color: #605e5c !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0 !important;
}

.userDropdown button:hover, .userDropdown.show button:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}