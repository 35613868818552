.tenantsDropdown button, .tenantsDropdown.show button, .tenantsDropdown.show button.dropdown-toggle, .tenantsDropdown button:focus, .tenantsDropdown button:active{
    border-radius: 0px !important;
    color: #605e5c !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0 !important;
}

.tenantsDropdown button:hover, .tenantsDropdown.show button:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}