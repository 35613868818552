.centre-left{
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    text-align: left;
}
.centre-right{
    text-align: right;
}
.centre{
    text-align: center;
}
.paddingTop{
    padding-top: 10%;
}
.btn-primary {
    color: #fff !important;
    background-color: #928475 !important;
    border-color: #928475 !important;
}
hr{
    display: -moz-inline-block;
    width:80%;
    text-align:right;
    margin-right:0
}
@media print{
    @page {
        size: landscape;
    }
    .footer {
        page-break-after: always;
    }
}


