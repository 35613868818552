.pagination {
    display: inline-block;
}
#viewDropDown{
    float: left;
    width: fit-content;
}

.pagination span {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
}

 .selected{
     color: blue;
     size: 18px;
 }

 .alignCenter
 {
     text-align: center;
 }

.totalRecordsFound
{
    font-size: 12px;
    color: grey;
   /* font-style: italic;*/
}
/* Style the buttons */
.pageBtn {
margin-left: 5px;
    cursor: pointer;
    font-size: 14px;
}

/* Style the active class, and buttons on mouse-over */
#pageNumberDiv .active, .pageBtn:hover {
    color: white !important;
    font-size: 14px;

    border-radius: 12px;
    background-color: #928475;

}

#pageNumberDiv{
    display: none;
}

.table th, .table td {
    vertical-align: middle;
}

.btn-primary, .btn-info {
    color: #fff !important;
    background-color: #928475 !important;
    border-color: #928475 !important;
}


.react-datepicker-wrapper input{
    border: 1px solid #ccc;
    min-height:37px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    font-family: inherit;
    border-radius: 4px;
    width: inherit;
    padding:8px;
}

.react-datepicker__input-container{

    padding-top: 10px;
}

.react-datepicker__close-icon::after {
    background-color: #928475 !important;
}





.Modal {
    position: absolute;
    top: 100px !important;
    left: 200px !important;
    right: 200px !important;
    bottom: 100px !important;
    background-color: white;
    opacity: 1 !important;
    border: 1px Solid #928475 ;
    border-radius: 5px;
z-index: 5;
    height:fit-content;
    width: fit-content;
    margin: auto;
    padding: 20px;
}
.ui.table thead tr:first-child > th {
    color: white;
    background-color:#928475 ;
    position: sticky !important;
    top: 0;
    border: 0;
    z-index: 0;
}

.ui.table thead tr:first-child>th:first-child{

    border-end-start-radius:5px;
    border-start-start-radius: 5px;
}
.ui.table thead tr:first-child>th:last-child{
    border-end-end-radius:5px;
    border-start-end-radius: 5px;
}


.paginationBttns {
    margin-top: 15px;

}
.paginationBttns a{
    border: 0px !important;
}
.paginationBttns a:hover {
    color: white !important;
    background-color:  #928475 !important;
}

.paginationActive a {

    color: white !important;
    background-color:  #928475 !important;
}


.deActive{
    color: lightgrey;
 /* //  opacity: 0.5;*/
}


.marginTopDiv{
    margin-top: 60px;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;


    z-index: 4;
}
#farmFilter   {
    width: 49.75%;
    float: left;
}
#rowFilter   {
    width: 49.75%;
    float: left;
}
#columnFilter   {
    margin-left: 0.5%;
    width: 49.75%;
    float: right;
}
#rootDiv{
    margin-top: 20px;
}
.react-datepicker-wrapper{
    margin-left: 0.5%;
    width: 49.75% !important;


}
@media only screen and (max-device-width: 640px) {
    #rootDiv{
        margin-top: 15px;
    }
    .Modal {
        position: relative;
        top: 0px !important;
        left: 0px !important;
        right: 0px !important;
        bottom: 0px !important;
        margin-top: 100px;
        margin-left: 80px;
        width: min-content;
    }
    #farmFilter   {
        width: 100%;

    }
    .react-datepicker-wrapper   {
        width: 100% !important;
        margin-left: 0;
    }
}
