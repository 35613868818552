a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar{
    position: absolute !important;
    right: 10px;
    top: 10px;
    text-align: left;
    background-color: #f8f9fa;
    border-radius: 50px;
    z-index: 999;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }

    .navbar{
        position: absolute;
        /*right: 20px;
        top: 10px;
        border-radius: 50px;*/
    }
}