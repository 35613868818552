.centre {
    width:100% !important;
}
.pt-0 {
    padding-top: 30px !important;
}
.floatRight{
    float: right;
}
.form-check-input-1{
position: center;
}
.width-mx{
   width: 100%;
}
.alignLoading
{
    margin-left: 50% !important;
    padding-bottom: 30px !important;
}
.floatLeft{
    float: left;
}
.floatBottom{
    float: bottom;
}
.card{
    border: 0;
    text-align: center;
}
.confirmBg {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #202020;
    opacity: 0.55;
    overflow: hidden; /* disable scrolling*/
    z-index: 2; /* higher than all other items, but lower than
    the confirm box*/
}
.input-group-text {
    background-color: #fff;
}

.paginationBttns {
    list-style: none;
    display: flex;
    justify-content: center;
}

.paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid grey;
    color: black;
    cursor: pointer;
}

.paginationBttns a:hover {
    color: white;
    background-color: grey;
}

.paginationActive a {
    color: white;
    background-color: grey;
}

.paginationDisabled a {
    color: grey;
    background-color: white;
}

.table thead tr:first-child > th {
    color: white;
    background-color:#928475 ;
    position: sticky !important;
    top: 0;
    border: 0;
    z-index: 2;
}

.table thead tr:first-child>th:first-child{

    border-end-start-radius:5px;
    border-start-start-radius: 5px;
}
.table thead tr:first-child>th:last-child{
    border-end-end-radius:5px;
    border-start-end-radius: 5px;
}

.pointer{
    cursor: pointer;
}

.table th, .table td {
    vertical-align: middle;
}