.farmsDropdown button, .farmsDropdown.show button, .farmsDropdown.show button.dropdown-toggle, .farmsDropdown button:focus, .farmsDropdown button:active{
    border-radius: 0px !important;
    color: #605e5c !important;
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 0 !important;
    border-right: #edebe9 solid 1px !important;
}

.farmsDropdown button:hover, .farmsDropdown.show button:hover {
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
}