#loading-container{
    padding-top: 15%;
    align-content: center;
}

.pulse-container {
    height: 400px;
    width: 400px;
    position: relative;
    margin: 0px auto 0;
    z-index: 9;
    padding-top: 100px;
}

#scan {
    animation-name: scan;
    animation-duration:2s;
    animation-iteration-count: infinite;
    fill: rgba(211, 47, 47, 0.75);
}

@keyframes scan {
    0% {transform:translateY(-13px);}
    50%{transform:translateY(13px);}
    100%{transform:translateY(-13px);}
}


.outer-scan {
    animation-name: outer-scan;
    animation-duration:1s;
    animation-iteration-count: infinite;
}

@keyframes outer-scan{
    0% {fill: rgba(211, 47, 47, 0.75);}
    25%{fill: #FFFFFF;}
    100%{fill: rgba(211, 47, 47, 0.75);}
}