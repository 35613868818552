#sidebar-wrapper{
    z-index: 2;
    max-width: 60px;
    min-width: 60px;
    background-color: #f8f9fa;
    display: inline-block;
}

#sidebar-wrapper .list-group {
    width: 100%;
}

#sidebar-wrapper .list-group a{
    border: 0px;
    background-color: transparent;
    padding-left: 23px;
    margin-top: 25px;
    color: #605e5c;
}

#sidebar-wrapper .list-group a.active{
    background-color: #e0e0e0 !important;
}

#sidebar-wrapper .list-group a:hover{
    background-color: #ebebeb !important;
}

#sidebar-wrapper .list-group a:hover span {
    display: inline !important;
}

.tooltip{
    z-index: 9999 !important;
}

#sidebar-wrapper {
    margin-left: 0;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
}

#sidebar-outer{
    z-index: 3;
    min-width: fit-content;
    margin-right: 0px !important;
}

#sidebar-outer .col:nth-child(1) {
    z-index: 1;
}